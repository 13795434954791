.alertsTable {
  max-width: 900px;

  & > tbody tr:nth-of-type(odd), tbody tr:hover {
    background-color: #f8f9fa !important;
  }

  & > thead th, td {
    border: 1px solid #dee2e6 !important;
  }

  & > tbody tr {
    cursor: pointer;
  }
}

.emptyMessage {
  color:  #254FA4;
}

.backButton {
  font-size: 18px;
  font-weight: 700;
}

.backButton:hover {
  font-weight: 800;
  text-decoration: none;
}

.alertsPageContainer {
  max-width: 900px;
}

.messageContainer {
  border-radius: 10px;
  background-color: #e6eefc;
  padding: 40px 80px;
}

@media only screen and (max-width: 800px) {
  .messageContainer {
    padding: 12px;
    margin: 12px;
  }
}