.AuthFooter {
  font-size: 0.9rem;
  a {
    margin: 0 0.5rem;
  }
}

.form-group {
  margin-bottom: 0.5rem !important;
}

.selectGroupText {
  font-size: 1.2rem;
}

.groupError {
  margin-bottom: 10px;
  color: red;
  font-size: 14px;
  display: block;
}

.groupSuccess {
  margin-bottom: 10px;
  color: #3B3CD4;
  font-size: 14px;
  display: block;
}

.previewContainer {
  justify-content: "center";
  align-items: "center";
  display: "flex";
  flex-direction: "column";
}

.previewCard {
  white-space: pre-wrap;
}

.forwardBackButton > button {
  font-size: 18px;
  font-weight: 700;
}

.forwardBackButton > button:hover {
  font-weight: 800;
  text-decoration: none;
}

.bottomText {
  font-size: 17px
}

.bottomText > a {
  font-weight: 800;
}

@media only screen and (max-width: 800px) {
  .previewCard {
    max-width: 100%;
    white-space: pre-wrap;
  }
}