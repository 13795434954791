.worksButton {
  font-size: 20px;
  margin: 30px 0;
  font-weight: 700;
  padding: 0;
}

.textContainer {
  max-width: none !important;
  text-align: left;
  border-radius: 10px;
  background-color: #e6eefc;
  padding: 40px 80px;
}