.SectionComponent {
  // Ensure container is above bgImage
  .container {
    position: relative;
  }
  @media screen and (max-width: 769px) {
    padding-top: 30px;
  }

  // Add light border if two white
  // sections next to each other.
  .bg-white + &.bg-white {
    border-top: 1px solid #F0F0F0;
  }
}
