@charset "utf-8";

// COLORS
$theme-colors: (
  "primary": #254FA4,
  "secondary": #6c757d,
  "success": #28a745,
  "danger": #ff4136,
  "warning": #ffc107,
  "info": #17a2b8,
  "light": #f8f9fa,
  "dark": #343a40,
  "white": #ffffff,
  "transparent": transparent
);

// TEXT
$font-family-sans-serif: "DM Sans", DM Sans, Arial, sans-serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem;

// BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

// SEE DOCS FOR MORE:
// https://bit.ly/2sgFMdb

// IMPORT BOOTSTRAP
@import "~bootstrap/scss/bootstrap";

// IMPORT FONT AWESOME
@import url("https://use.fontawesome.com/releases/v5.10.1/css/all.css");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

#root {
  // Full height of screen and flex so that we can push footer
  // to the bottom even if content is shorter than screen
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // Push footer to the bottom of the screen
  > .footer {
    margin-top: auto;
  }
  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    flex-shrink: 0;
  }
}

// Disable arrows on number input
// Chrome, Safari, Edge, Opera 
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Firefox 
input[type=number] {
  -moz-appearance: textfield;
}

.formContainer {
  border-radius: 10px;
  background-color: #e6eefc;
  width: 55%;
  padding: 40px 80px;
}

@media only screen and (max-width: 800px) {
  .formContainer {
    width: 100%;
    padding: 12px;
  }
}